import { onCleanup, onMount } from 'solid-js';
import type { AuthUser } from '../api';
import { UPDATERS } from './updaters';

export interface Props {
  user?: AuthUser;
}

export function StorageUpdater(props: Props) {
  const timeouts: NodeJS.Timeout[] = [];

  const update = async () => {
    for (const updater of UPDATERS) {
      try {
        await updater.update(props.user);
      } catch (error) {
        // do nothing
      }
    }
  };

  const scheduleRefresh = () => {
    for (const updater of UPDATERS) {
      const timeoutId = setInterval(() => {
        updater.update(props.user);
      }, updater.intervalInMs);

      timeouts.push(timeoutId);
    }
  };

  onMount(() => {
    update();
    scheduleRefresh();
  });

  onCleanup(() => {
    for (const timeoutId of timeouts) {
      clearInterval(timeoutId);
    }
  });

  return;
}
